











































































// Components
import { Component, Vue } from 'vue-property-decorator';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getDashboardData from '@/graphql/getDashboardData.graphql';

// Dates
import { addMinutes, startOfDay } from 'date-fns';

import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption';  // eslint-disable-line
import { formatDate } from '@/utils/dateHelpers';
import { ucfirst } from '@/utils/helpers'; // eslint-disable-line

const appModule = namespace('appModule');

@Component
export default class CentralLoggingTable extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public bugsnagItems: {
    id: number,
    event: string,
    traceback: string,
    time: string,
  }[] = [];

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;

    /*
      Fetch central logging
     */
    const {
      data:
        {
          get_dashboard_data: {
            results: bugsnagData,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getDashboardData,
      variables: {
        input: {
          start_date:
            formatDate(startOfDay(addMinutes(
              new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
            )), "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
            "yyyy-MM-dd'T'HH:mm:ss"),
          env: 'prod',
          record_type: 'bugsnag',
        },
      },
    });
    this.bugsnagItems = bugsnagData.map((i) => JSON.parse(i)).map((i, idx) => ({
      id: idx,
      event: ucfirst(i.severity),
      traceback: i.exceptions[0]?.message,
      time: formatDate(new Date(i.received_at), 'yyyy-MM-dd pp'),
    }));
    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
